import { useEffect } from 'react';
import lozad from 'lozad';

function useLazyload(dependencies: Array<any> = []) {
  useEffect(() => {
    const getAllImages = document.querySelectorAll('.lazyload');

    if (getAllImages) {
      const observer = lozad(getAllImages); // lazy loads elements with default selector as '.lazyload'
      observer.observe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}

export default useLazyload;
