import { PLANS_FEATURES_DISPLAY_NAME } from '@constants/pricing';
import { Cookies } from '@utils/cookie.utils';
import { AiPlan, PlanResponse, PricingPlans } from 'src/types/pricing.types';

export function serialisePlan(
  resPlan: PlanResponse[],
  currentPlans: PricingPlans,
  options: { isLoggedIn?: boolean; theme?: 'light' | 'dark' } = {},
) {
  const { isLoggedIn = false, theme } = options;

  try {
    const currentPlanClone: PricingPlans = JSON.parse(
      JSON.stringify(currentPlans),
    );
    const assetsHost = `${process.env.NEXT_PUBLIC_CDN_URL}/homepage`;
    const baseHost =
      `${process.env.NEXT_PUBLIC_STUDIO_HOST}` || 'https://studio.invideo.io';

    if (currentPlanClone && Array.isArray(currentPlanClone)) {
      const newPlanData = currentPlanClone.map(currentPlan => {
        const currentResPlan = resPlan.find(
          plan => plan.name === currentPlan.name,
        );

        if (!currentResPlan) {
          return currentPlan;
        }

        currentPlan = { ...currentPlan, ...currentResPlan };

        if (currentPlan.CTA?.monthly?.url) {
          currentPlan.CTA.monthly.url = baseHost + currentPlan.CTA.monthly.url;
        }
        if (currentPlan.CTA?.annually?.url) {
          currentPlan.CTA.annually.url =
            baseHost + currentPlan.CTA.annually.url;
        }
        if (currentPlan.redirection) {
          currentPlan.redirection = {
            url: baseHost + currentPlan.redirection,
            type: '',
          };
        }

        if (currentPlan.featuresCheckImage === '') {
          if (theme === 'dark') {
            currentPlan.featuresCheckImage = `${assetsHost}/check-icon.svg`;
          } else {
            currentPlan.featuresCheckImage =
              'https://web-assets.invideo.io/common/prod/icons/green-tick.png';
          }
        }

        if (
          currentPlan.appPromo &&
          !currentPlan.appPromo?.appImage &&
          currentPlan.label === 'Business'
        ) {
          currentPlan.appPromo.appImage = assetsHost + '/filmr-mobile.png';
        }

        if (
          currentPlan.appPromo &&
          !currentPlan.appPromo?.appImage &&
          currentPlan.label === 'Unlimited'
        ) {
          currentPlan.appPromo.appImage =
            assetsHost + '/filmr-mobile-unlimited.png';
        }

        if (
          currentPlan.planPrice &&
          currentPlan.planPrice &&
          currentPlan.label !== 'Free' &&
          currentResPlan
        ) {
          currentPlan.planPrice.monthly.price =
            currentResPlan.durations[0].monthly_price_display;

          currentPlan.planPrice.annually.price = Math.round(
            currentResPlan.durations[1].monthly_price_display,
          );

          currentPlan.planPrice.annually.starText =
            currentPlan.planPrice.annually.starText?.replace(
              /\$\d+/,
              '$' + currentResPlan.durations[1].sellingPrice,
            );
        }

        currentPlan.CTA.monthly.text =
          isLoggedIn &&
          currentResPlan.durations[0].planAction?.action === 'NONE'
            ? 'Current Plan'
            : currentResPlan.durations[0].planAction?.displayText || '';

        currentPlan.CTA.monthly.url =
          currentResPlan.durations[0].planAction?.actionUrl || '';
        currentPlan.CTA.monthly.action =
          currentResPlan.durations[0].planAction?.action;

        currentPlan.CTA.annually.text =
          isLoggedIn &&
          currentResPlan.durations[1].planAction?.action === 'NONE'
            ? 'Current Plan'
            : currentResPlan.durations[1].planAction?.displayText || '';

        currentPlan.CTA.annually.url =
          currentResPlan.durations[1].planAction?.actionUrl || '';
        currentPlan.CTA.annually.action =
          currentResPlan.durations[1].planAction?.action;

        if (currentResPlan.durations) {
          currentPlan.durations = currentResPlan.durations;
        }

        return currentPlan;
      });

      return newPlanData;
    }
    return currentPlanClone;
  } catch (error) {
    console.log(error);
    return currentPlans;
  }
}

export function setPlanForCheckout({
  plan,
  duration,
  couponCode,
  domain,
}: {
  plan: string;
  duration: boolean;
  couponCode?: string;
  domain?: string;
}) {
  const data = {
    plan: plan,
    duration: duration,
    coupon: couponCode,
  };
  const cookieName = window.btoa('pricing_plan');
  const cookieValue = JSON.stringify(data);

  Cookies.set(cookieName, cookieValue, {
    path: '/',
    domain,
  });
}

export function setCookieForCoupon(couponCode: string, response: any) {
  const couponResponse = { ...response };

  if (couponResponse.pricing_plans) {
    delete couponResponse.pricing_plans;
  }

  const data = {
    coupon: couponCode,
    response: couponResponse,
  };

  const domain = window.location.hostname;

  const cookieName = window.btoa('coupon');
  const cookieValue = JSON.stringify(data);

  Cookies.set(cookieName, cookieValue, { sameSite: 'Lax', domain });
}

export const replaceTripleBraces = (template: string, json: any) => {
  if (template) {
    return template.replace(/{{{(.+?)}}}/g, (_, g1) => json[g1] ?? g1);
  }
  return '';
};

const getTickByPlan = (plan: string) => {
  const assetUrl = process.env.NEXT_PUBLIC_SHARED_CDN_URL || '';
  switch (plan) {
    case 'plus':
      return `${assetUrl}/pricing/launch-tick.svg`;
    case 'max':
      return `${assetUrl}/pricing/grow-tick.svg`;
    default:
      return `${assetUrl}/pricing/tick.svg`;
  }
};

const getUsersLimitFeature = (plan: string) => {
  if (plan === 'plus' || plan === 'max' || plan === 'generative') {
    return 'Upto {{{quota}}} users';
  }
  return '';
};

export const serializeAiPlans = (plans: any, current_plans: AiPlan[]) => {
  const assetsUrl = process.env.NEXT_PUBLIC_SHARED_CDN_URL;
  return current_plans.map(plan => {
    const monthlyPricing = plans[plan.key]['monthly'];
    const yearlyPricing = plans[plan.key]['yearly'];

    const planPrice = {
      monthly: {
        currency: monthlyPricing.symbol || '$',
        price: monthlyPricing.amount,
        planId: monthlyPricing.plan_id,
      },
      yearly: {
        currency: yearlyPricing.symbol || '$',
        price: yearlyPricing.amount / 12,
        planId: yearlyPricing.plan_id,
      },
    };

    const usageFeatures = plans[plan.key].usage_features ?? [];

    const key_features = usageFeatures
      .filter((f: any) => f.top_feature)
      .map((f: any) => {
        let icon =
          f.quota > 0 || !['istock', 'voice'].includes(f.feature)
            ? `${assetsUrl}/pricing/tick.svg`
            : `${assetsUrl}/pricing/cross.svg`;

        if (f.feature === 'ai_credits') {
          if (plan.key === 'free') {
            icon = `${assetsUrl}/pricing/cross.svg`;
          } else {
            icon = `${assetsUrl}/pricing/tick.svg`;
          }
        }
        return {
          icon,
          text: replaceTripleBraces(f.feature_description, {
            quota_unit: f.quota_unit,
            recurrence: f.recurrence,
            quota: '{{{quota}}}',
          }),
          name: f.feature,
          quota: f.quota,
          help: replaceTripleBraces(f.tooltip_description, {
            quota_unit: f.quota_unit,
            recurrence: f.recurrence,
            quota: f.quota,
          }),
          style:
            f.quota == 0 && f.feature === 'premium_stock'
              ? 'iv-line-through'
              : '',
        };
      });

    const userLimit = getUsersLimitFeature(plan.key);

    if (userLimit) {
      key_features.push({
        icon: `${assetsUrl}/pricing/tick.svg`,
        text: userLimit,
        name: 'user_limit',
        quota: 1,
      });
    }

    return {
      ...plan,
      planPrice,
      key_features,
      sequence: plans[plan.key].sequence,
    };
  });
};

export const serializeAiPlansFeatures = (
  plans: any,
  current_plans: AiPlan[],
  features: any,
) => {
  const assetsUrl = process.env.NEXT_PUBLIC_SHARED_CDN_URL;
  current_plans.forEach(plan => {
    const usageFeatures = plans[plan.key].usage_features ?? [];

    usageFeatures.forEach((f: any) => {
      if (features[f.feature]) {
        features[f.feature] = {
          ...features[f.feature],
          help: replaceTripleBraces(f.tooltip_description, {
            quota_unit: f.quota_unit,
            recurrence: f.recurrence,
            quota: f.quota,
          }),
          [plan.key]: {
            icon: getTickByPlan(plan.key),
            text: replaceTripleBraces(f.detailed_description, {
              quota_unit: f.quota_unit,
              recurrence: f.recurrence,
              quota: '{{{quota}}}',
            }),
            name: f.feature,
            quota: f.quota,
            style: '',
          },
        };
      } else {
        features[f.feature] = {
          name: f.feature,
          displayName: PLANS_FEATURES_DISPLAY_NAME[f.feature],
          help: replaceTripleBraces(f.tooltip_description, {
            quota_unit: f.quota_unit,
            recurrence: f.recurrence,
            quota: f.quota,
          }),
          [plan.key]: {
            icon:
              f.detailed_description?.toLowerCase() === 'not included'
                ? `${assetsUrl}/pricing/cross.svg`
                : getTickByPlan(plan.key),
            text: replaceTripleBraces(f.detailed_description, {
              quota_unit: f.quota_unit,
              recurrence: f.recurrence,
              quota: '{{{quota}}}',
            }),
            name: f.feature,
            quota: f.quota,
            style:
              f.detailed_description?.toLowerCase() === 'not included'
                ? 'iv-opacity-30'
                : '',
          },
        };
      }
    });
  });
  return features;
};
