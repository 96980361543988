import React, { useContext } from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';

import IVButton from '@invideoio/web-shared/components/Button';
import { AUTH_TYPE, HOMEPAGE } from '../../constants/homepage';
import { RedirectContext, StateContext } from '../../store/store';
import {
  AmplitudeEvents,
  sendAmplitudeAnalyticEvent,
  sendRudderStackAnalyticEvent,
} from '@utils/analytics';

import ImageComponent from '@components/common/ImageComponent/ImageComponent';
import {
  ClickCtaUxSource,
  ClickCtaType,
  AnalyticsProductType,
} from 'src/types/analytics.types';
import { SideTextType, SimpleHeadingType } from 'src/types';
import { BaseAppType } from '@constants/common';

export interface SuperbVideosProps {
  heading: SimpleHeadingType;
  detail?: SideTextType;
  buttonText: string;
  CTAUrl: string;
  layoutV3?: boolean;
  hideLogo?: boolean;
}

const SuperbVideoCTA = (props: SuperbVideosProps) => {
  const { baseAppType } = useContext(StateContext);
  const { heading, detail, buttonText, CTAUrl, layoutV3, hideLogo } = props;
  const { logo } = HOMEPAGE.superbVideo;
  const { handleClick } = useContext(RedirectContext);
  const onClick = () => {
    sendAmplitudeAnalyticEvent(AmplitudeEvents.clickSignUp, {
      btnPosition: 'Bottom',
    });
    sendRudderStackAnalyticEvent({
      event_name: 'click_cta',
      event_properties: {
        ux_source: ClickCtaUxSource.Bottom,
        type: ClickCtaType.ClickSignup,
        feature_utm: 'na',
        product:
          baseAppType === BaseAppType.AI
            ? AnalyticsProductType.AI
            : AnalyticsProductType.STUDIO,
      },
    });
    handleClick(CTAUrl, { tab: AUTH_TYPE.SIGNUP });
  };

  return (
    <section
      className={ivclass(
        'iv-bg-grey-100',
        !layoutV3 && 'xl:iv-mt-200 iv-mt-150 sm:iv-mt-100',
        'xl:iv-py-100 iv-pt-30 iv-pb-35',
        'iv-text-center',
      )}
    >
      {!hideLogo && (
        <div
          className={ivclass(
            'iv-inline-block',
            'iv-my-35 sm:iv-my-[42px]',
            'iv-relative',
            'iv-h-[54px] sm:iv-h-[37px]',
            'iv-w-[56px] sm:iv-w-[36px]',
          )}
        >
          <ImageComponent
            elementype="native"
            src={logo.url}
            alt={logo.alt}
            loading="lazy"
          />
        </div>
      )}
      <h2
        className={ivclass(
          'xl:iv-pt-40 iv-pt-[24px] sm:iv-pt-7 md:iv-px-35 sm:iv-px-20',
          'iv-text-center',
          'iv-text-grey-0',
          'xl:iv-text-heading-5xl iv-text-heading-4xl sm:iv-text-heading-s',
          'xl:iv-m-heading-5xl iv-m-heading-4xl sm:iv-m-heading-s',
          'iv-font-extrabold',
          'md:iv-w-full sm:iv-w-full',
        )}
      >
        <span className={ivclass()}>{heading.plainHeading}</span>
        <span
          className={ivclass(
            'iv-block',
            'iv-text-center',
            'iv-title-gradient',
            'iv-font-extrabold',
          )}
        >
          {heading.highlightedHeading}
        </span>
      </h2>
      {detail && (
        <div
          className={ivclass(
            'iv-px-20',
            'iv-pt-60 sm:iv-pt-40',
            'iv-text-center',
            'iv-text-grey-0',
            'xl:iv-text-heading-3xs lg:iv-text-heading-3xs md:iv-text-heading-4xs sm:iv-text-body-2xl',
            'xl:iv-m-heading-3xs lg:iv-m-heading-3xs md:iv-m-heading-4xs sm:iv-m-body-2xl',
            'iv-font-semibold',
          )}
        >
          <div>{detail.highlightedText}</div>
          <span
            className={ivclass('iv-block sm:iv-inline', 'iv-text-white-50')}
          >
            {detail.plainText1}
          </span>
          <span
            className={ivclass('iv-block sm:iv-inline', 'iv-text-white-50')}
          >
            {detail.plainText2}
          </span>
        </div>
      )}
      <IVButton
        className={ivclass(
          'iv-bg-blue hover:iv-bg-blue-strong iv-transition',
          'iv-flex',
          'iv-justify-center',
          'iv-items-center',
          'iv-cursor-pointer',
          'iv-rounded-full',
          'xl:iv-mt-80 iv-mt-75 sm:iv-mt-60',
          'xl:iv-mb-50 iv-mb-40',
          'iv-mx-auto',
          'xl:iv-px-20 iv-px-20',
          'xl:iv-h-65 iv-h-52',
          'xl:iv-text-body-3xl iv-text-body-2xl',
          'iv-text-grey-0 iv-font-bold',
        )}
        onClick={onClick}
      >
        {buttonText}
      </IVButton>
    </section>
  );
};

export default SuperbVideoCTA;
