import { AnalyticsType } from '@invideoio/web-shared/types/Analytics';
import { Analytics } from '@invideoio/web-shared/utils/analytics';
import { AnalyticsUtils } from '@invideoio/web-shared/utils/analytics/AnalyticsUtils';
import { sanitizeParams } from '@invideoio/web-shared/utils/auth/check-params';
import { isClassicEligible } from '@invideoio/web-shared/utils/eligibility/classicEligibility';
import {
  ChangeAddOnSeatsAnalytics,
  ChangeSeatsAnalytics,
  ClickCtaAnalytics,
  ExperimentViewedAnalytics,
} from 'src/types/analytics.types';

export enum AmplitudeEvents {
  appLoad = 'App Load',
  clickBanner = 'Click Banner',
  clickSignUp = 'Click Signup',
  clickLogin = 'Click Login',
  clickResetPassword = 'Click Reset Password', // pragma: allowlist secret
  clickSupport = 'Click Support',
  clickTutorial = 'Click Tutorial',
  clickSocialHandle = 'Click Social Handle',
  clickWebinar = 'Free Webinar Clicked',
  contactEnterprise = 'Contact Enterprise',
  compareFeatures = 'Compare Features',
  openTemplate = 'Open Template',
  openVideoWallTemplate = 'Open Video Wall Template',
  exploreTemplates = 'Explore Templates',
  exploreThemes = 'Explore Themes',
  goToEditor = 'Go to Editor',
  experimentViewed = 'Experiment Viewed',
}

export const sendAmplitudeAnalyticEvent = (
  eventName: string,
  eventData: any = {},
  userData: any = {},
) => {
  Analytics.send(AnalyticsType.AMPLITUDE, {
    eventName,
    eventProperties: {
      Pagetype: AnalyticsUtils.getPage(),
      Pagesource: sanitizeParams(),
      ...eventData,
    },
    userProperties: {
      ...userData,
    },
  });
};

export const sendRudderStackAnalyticEvent = (
  data:
    | ClickCtaAnalytics
    | ExperimentViewedAnalytics
    | ChangeSeatsAnalytics
    | ChangeAddOnSeatsAnalytics,
) => {
  const eventName = data.event_name;
  const eventProperties = data.event_properties;

  let userProperties = {};
  if ('user_properties' in data) {
    userProperties = data.user_properties;
  }

  return Analytics.send(AnalyticsType.RUDDER_STACK, {
    eventName,
    eventProperties: {
      ...AnalyticsUtils.commonRudderStackEventProperties(),
      ...AnalyticsUtils.toLowerCaseObject((eventProperties as any) ?? {}),
    },
    userProperties: {
      ...AnalyticsUtils.toLowerCaseObject(userProperties ?? {}),
    },
  });
};

export const setCommonAnalyticsProperties = () => {
  import('@invideoio/web-shared/Modules/IVUAParser').then(module => {
    const UAParser = module.default;
    const deviceSpec = AnalyticsUtils.getDeviceConfig();
    const parser = new UAParser(window.navigator.userAgent);
    const { device, os, browser } = parser.getResult();
    const appSource = device.type ? 'mobile' : 'web';

    AnalyticsUtils.setCommonProperties({
      isClassic: isClassicEligible(),
      isUploadExperiment: false,
      app_source: appSource,
      browser: {
        name: browser.name ?? 'na',
        version: browser.version ?? 'na',
      },
      os: {
        name: os.name ?? 'na',
        version: os.version ?? 'na',
      },
      device_info: {
        cpu: deviceSpec.cpu,
        ram: deviceSpec.ram,
      },
      isEligibleForRudderstackAnalytics: true,
    });
  });
};
