/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import React, { forwardRef, ImgHTMLAttributes } from 'react';
import Image, { ImageProps } from 'next/image';
import Head from 'next/head';
import CustomImageLoader from '@utils/imageLoader';

interface BaseImageComponentProps {
  preload?: boolean;
}

interface NativeImageComponentProps
  extends React.PropsWithChildren<ImgHTMLAttributes<HTMLImageElement>>,
    BaseImageComponentProps {
  elementype: 'native';
}

interface NextImageComponentProps extends ImageProps, BaseImageComponentProps {
  elementype: 'next';
}

type ImageComponentProps = NativeImageComponentProps | NextImageComponentProps;

const ImageComponent = forwardRef<HTMLImageElement, ImageComponentProps>(
  (props, ref) => {
    const { elementype, preload } = props;

    if (preload && typeof props.src === 'string') {
      <Head>
        <link
          key={'__ivimg-' + props.src}
          rel="preload"
          as="image"
          href={props.src}
          // @ts-ignore: imagesrcset is not yet in the link element type.
          imagesrcset={imgAttributes.srcSet}
          // @ts-ignore: imagesizes is not yet in the link element type.
          imagesizes={imgAttributes.sizes}
        ></link>
      </Head>;
    }

    if (elementype === 'native') {
      const { children, elementype, ...imageProps } = props;
      if (!children) {
        return <img {...imageProps} ref={ref} />;
      }

      return (
        <picture className="" {...imageProps} ref={ref}>
          {children}
        </picture>
      );
    }

    if (elementype === 'next') {
      const { elementype, ...imageProps } = props;
      return <Image loader={CustomImageLoader} {...imageProps} />;
    }

    return null;
  },
);

ImageComponent.displayName = 'ImageComponent';

export default ImageComponent;
