import React from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import { SimpleHeadingType } from 'src/types';

interface TitleProps {
  heading: SimpleHeadingType;
  theme?: 'dark' | 'light';
  spanClass?: string;
  headingClass?: string;
}

function TitleH2BlockGradient({
  heading,
  theme = 'light',
  spanClass = 'iv-block',
  headingClass = '',
}: TitleProps) {
  return (
    <h2
      className={ivclass(
        theme === 'dark'
          ? 'iv-text-grey-0'
          : 'iv-text-grey-100 dark:iv-text-grey-0',
        'iv-text-heading-4xl lg:iv-text-heading-2xl md:iv-text-heading-2xl sm:iv-text-heading-2xs',
        'iv-m-heading-3xl lg:iv-m-heading-2xl md:iv-m-heading-2xl sm:iv-m-heading-2xs',
        'iv-font-extrabold iv-text-center iv-w-full iv-h-full',
        headingClass,
      )}
      suppressHydrationWarning={true}
    >
      {heading.plainHeading}
      <span
        className={ivclass(
          'iv-h-full',
          'iv-title-gradient',
          // theme === 'dark' && 'dark:iv-gradient-deep-blue-blue-turquoise',
          spanClass,
        )}
      >
        {heading.highlightedHeading}
      </span>
    </h2>
  );
}

export default TitleH2BlockGradient;
