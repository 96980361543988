const CustomImageLoader = ({ src, width, quality }: any) => {
  let assetPrefix = '';

  if (
    process.env.NEXT_PUBLIC_ENV === 'production' ||
    process.env.NEXT_PUBLIC_ENV === 'staging'
  ) {
    assetPrefix = '/web-landing-pages-assets';
  }

  return `${assetPrefix}/_next/image/?url=${src}&w=${width}&q=${quality || 70}`;
};

export default CustomImageLoader;
